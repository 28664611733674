/******************************************************
 * 
 * This page is designed to improve the % of people that enter company data,
 * 
 * 
 * Anything else so say to convince them?
 * 
 * 
pull data in (1 hour)
send data back (1 hour)
redirect (1 hour)

 * 
 * ****************************************************/

/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState,useRef,useEffect } from 'react';
//import { useAppContext } from "../libs/contextLib";
import withWrapper from '../components/wrapper.js';
import { navigate, Link } from "gatsby";
import checkmark from '../images/checkmark.svg';
import { Helmet } from "react-helmet";


import './signup.css';
let key="";
var cols=['Net Income','Gross Profit','Operating Expenses','Total Assets','Total Liabilities'];
function _inner (props) {
  const tableRef = useRef(null);
  const [data, setData] = useState([""]);
  const [co, setCo] = useState(0);
  //loadPrivateCo();
  function setDatas(val,idx) {
      var d=[...data];
      d[idx]=val;
      setData(d);
      return true;
  }
  
  function updateCompany() {
    let fins=co.financials;
    
    let keys=Object.keys(fins);
    for(let i=0;i<cols.length;i++) {
      //find it in the keys
      for(let k=0;k<keys.length;k++) {
        if(keys[k].indexOf(cols[i])>-1) {
          fins[keys[k]][fins[keys[k]].length-1]=parseFloat(data[i]);
          break;
        }
      }
    }
    console.log("updated fins");
    console.log(fins);
    //modify fins
    co.financials=fins;
      fetch("https://api.valtrace.com/updateCompany", {
        method: "POST",
        body: JSON.stringify(co),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': key
        }
      }).then(res => res.text())
      .then(res => {
        res=JSON.parse(res);
        console.log("res");
        console.log(res);
        console.log("done...updateCompany");
      });
  }

  function loadPrivateCo(name) {
    if(co==0) {
      console.log("loadPrivateCo!!!!");
        fetch("https://api.valtrace.com/privateFinancials", {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'text/plain',
              'Authorization': key
            }
        }).then(res => res.text())
        .then(res => {
          let companies=JSON.parse(res);
          console.log(companies);
          if(companies.companies.length>0) {
            console.log("Setting co");
            setCo(companies.companies[0]);
          }
          console.log("co");
          console.log(co);
          //this.setState({financials:financials.companies[0].financials});
          //this.companyAbout.current.style.display='inline-block';
        });
    }
  }

  function validateForm() {
    if(tableRef.current==null) 
        return false;
    loadPrivateCo();
    console.log(data);
    var sumVal=0;
    for(var i=0;i<cols.length;i++) {
        var st=tableRef.current.children[i].children[1].children[1].style;
        if(!isNaN(parseInt(data[i],10))) {
            st.visibility='visible';
            sumVal++;
        } else
            st.visibility='hidden';
    }
    let v=!(sumVal==cols.length);
    return v;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    updateCompany();
    //setIsLoading(true);
  }

  let topstyle={position: 'relative',
    width: '100%',
  };
  
  let innerStyle={
    
  };
  
  return (
    <div className="LoginForm" style={topstyle} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quick Estimate</title>
        <link rel="canonical" href="https://www.valtrace.com/quickEstimate/" />
        <html lang="en" />
      </Helmet>
      <div style={innerStyle} >
        <style>{`
          .info_table td {
            padding:10px;
            padding-left:0px;
            border-bottom:1px solid hsla(0,0%,0%,0.06);
          }
          .companyInfo {
            text-align: center;
            /* May want to do this if there is risk the container may be narrower than the element inside */
            white-space: nowrap;
          }
           
          /* The ghost, nudged to maintain perfect centering */
          .companyInfo:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em; /* Adjusts for spacing */
          }
          
          /* The element to be centered, can also be of any width and height */ 
          .bl_centered {
            display: inline-block;
            vertical-align: middle;
            width: 300px;
          }  
            
          li {
            margin-bottom: 1px;
          }
          .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            border-right:10px solid #aaa;
          }
          .LoginForm input {
            border-radius: 2px;
            border: 1px solid #aaa;
            padding: 2px;
          }
          
          .linet {
            overflow: hidden;
            text-align: center;
          }
          
          .linet:before,
          .linet:after {
            background-color: hsla(0,0%,0%,0.24);
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
          }
          
          .linet:before {
            right: 0.5em;
            margin-left: -50%;
          }
          
          .linet:after {
            left: 0.5em;
            margin-right: -50%;
          }
        `}
        </style>
        <div style={{padding:'20px',paddingTop:'0px',textAlign:'center',fontSize:'18px'}}>
          <div>Quick start your valuation</div>
          <div>with just a few lines.</div>
          <div>(you can refine later)</div>
        </div>
        <div className='companyInfo' style={{}}>
          <div className='bl_centered' style={{minWidth:'380px' }}>
            <form onSubmit={handleSubmit} style={{marginBottom:'0'}}>
              <div className="linet">Last Quarter's Financials</div>
              <table className="info_table" style={{marginBottom:'0',fontSize:'15px'}}>
                <tbody ref={tableRef}>
                {cols.map((val, idx) => {
                  return <tr>
                    <td style={{textAlign:'right'}}><span style={{display: 'inline-block', width: '150px'}}>{val}</span></td>
                    <td>
                      <input style={{width:'140px'}} value={data[idx]} onChange={e => setDatas(e.target.value,idx)} />
                      <img src={checkmark} style={{position:'relative',right:'13px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                    <td style={{width:'50px',paddingLeft:'15px'}}>
                    {(idx>=0 && idx<2 && !isNaN(parseFloat(data[idx])/parseFloat(data[idx+1])) ) &&
                      <span style={{position:'relative',top:'10px',color:'green',fontSize:'90%'}}>{ (parseFloat(data[idx])/parseFloat(data[idx+1])*100).toFixed(1)}%</span>
                    }
                    </td>
                  </tr>;
                })}
                </tbody>
              </table>
              <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
                <button type="submit" className='btn' disabled={validateForm()} >Initial Valuation</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function setKey(tkey) {
  key=tkey;
}

export default withWrapper(_inner,{},setKey);